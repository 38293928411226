.Wrapper {
  width: 100%;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
  max-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}

.Table_Container {
  overflow-x: auto;
  border-radius: 4px;
  background-color: #fff;
}

.Table {
  width: 100%;
  display: table;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  border-spacing: 0;
  border-collapse: collapse;
}

.Row {
  color: inherit;
  height: 48px;
  display: table-row;
  outline: none;
  vertical-align: middle;
}

tbody tr:hover {
  background-color: rgba(224, 224, 224, 0.3);
}

.Cell {
  display: table-cell;
  padding: 4px;
  text-align: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  vertical-align: inherit;
}

.Cell_Header {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 10;
  text-align: center;
  background-color: #fff;
}

.Cell_Header_Content {
  width: 50%;
}

.Cell_Body {
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.8125rem;
  font-weight: 400;
}

.Pagination_Container {
  height: 56px;
  min-height: 56px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(224, 224, 224, 1);
}
